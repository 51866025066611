<template>
  <div class="fixed z-50 top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black bg-opacity-75" v-if="show">
    <div class="flex flex-col max-h-full overflow-y-auto max-w-screen-sm bg-gray-100 rounded shadow-2xl" :class="[($slots.footer ? '' : 'pb-6'), width]" ref="container">
      <div ref="header" class="sticky inset-x-0 top-0 bg-gray-100 py-3 md:py-6 px-6 relative z-50">
        <a class="absolute cursor-pointer right-0 top-0 p-3 md:p-6" @click="close">
          <i class="fas fa-times fa-lg text-gray-600 hover:text-gray-800"></i>
        </a>
        <div class="pr-6" v-show="$slots.title">
          <slot name="title" />
        </div>
      </div>
      <div ref="body" class="px-6">
        <slot />
      </div>
      <div ref="footer" class="sticky inset-x-0 bottom-0 bg-gray-100 py-3 md:py-6 px-6 z-50" v-show="$slots.footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      width: String,
      default: null
    },
    data() {
      return {
        show: false,
        observer: null
      }
    },
    updated() {
      this.scrolled()
    },
    methods: {
      scrolled() {
        if (this.$refs.container) {
          if (this.$refs.container.scrollTop > 0) {
            this.$refs.header.classList.add("shadow-md")
          } else {
            this.$refs.header.classList.remove("shadow-md")
          }
          const bound = this.$refs.container.scrollTop + this.$refs.container.offsetHeight
          if (bound < this.$refs.container.scrollHeight) {
            this.$refs.footer.classList.add("shadow-top")
          } else {
            this.$refs.footer.classList.remove("shadow-top")
          }
        }
      },
      keypressed(key) {
        if (key.code === 'Escape') {
          this.close()
        }
      },
      open() {
        this.show = true
        document.body.classList.add('overflow-hidden')
        document.addEventListener('keydown', this.keypressed)
        this.$nextTick(() => {
          this.$refs.container.addEventListener('scroll', this.scrolled)
          this.scrolled()
        })
        this.$emit("opened")
      },
      close() {
        this.show = false
        if (this.$refs.container) {
          this.$refs.container.removeEventListener('scroll', this.scrolled)
        }
        document.body.classList.remove('overflow-hidden')
        document.removeEventListener('keydown', this.keypressed)
        this.$emit("closed")
      }
    }
  }
</script>
