import { parseISO, format } from 'date-fns'
import { fr } from 'date-fns/locale'

export default (value, fmt = 'dd/MM/yyyy HH:mm') => {
  if (value == null) {
    return 'n/a'
  } else if (typeof value == "string") {
    return format(parseISO(value), fmt, { locale: fr })
  } else {
    return format(value, fmt, { locale: fr })
  }
}
