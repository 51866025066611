// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

import "../../stylesheets/front.scss"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
Vue.use(TurbolinksAdapter)

import axios from 'axios'
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name=csrf-token]").content

import vueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(vueSmoothScroll)

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
Vue.component('vueper-slides', VueperSlides)
Vue.component('vueper-slide', VueperSlide)

import ProductIndex from '../../components/toriya/front/ProductIndex'
Vue.component('product-index', ProductIndex)
import Cart from '../../components/toriya/front/Cart'
Vue.component('cart', Cart)
import OrderForm from '../../components/toriya/front/OrderForm'
Vue.component('order-form', OrderForm)
import OrderScheduleForm from '../../components/toriya/front/OrderScheduleForm'
Vue.component('order-schedule-form', OrderScheduleForm)

export const OrderBus = new Vue()

document.addEventListener('turbolinks:load', () => {
  new Vue({
    el: '#application',
    data() {
      return {
        order: {}
      }
    },
    mounted() {
      this.order = JSON.parse(this.$el.dataset.order)
      OrderBus.$emit('order:updated', this.order)
      window.addEventListener('scroll', this.scrolled)
      this.scrolled()
    },
    methods: {
      scrolled() {
        if (this.$refs.header) {
          if (window.scrollY === 0) {
            this.$refs.header.classList.remove('shadow-lg')
          } else {
            this.$refs.header.classList.add('shadow-lg')
          }
        }
      },
      toggleNavigation() {
        this.$refs.header.classList.remove('bg-opacity-75')
        this.$refs.navigation.classList.toggle('hidden')
      }
    }
  })
})
